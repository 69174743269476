// Navbar
//
// Provide a static navbar from which we expand to create full-width, fixed, and
// other navbar variations.

.navbar {
  display: block;
  @extend .shadow-sm;
  %container-flex-properties {
    display: block;
  }
  &-toggler-icon {
    height: 2rem;
    width: 2rem
  }
}

// Navbar nav
//
// Custom navbar navigation (doesn't require `.nav`, but does make use of `.nav-link`).

.navbar-nav {
  border-top: $border-style .0625rem $gray-001;
  justify-content: space-between;
  width: 100%;
}