// Default Pagination
//

.pagination {
    border: solid 1px $gray-300;
    display: inline-flex;
    flex-wrap: nowrap; // Remove if issue anywhere
    justify-content: space-between;
    margin: 0;
    @include border-radius(.25rem);
    .page-item {
        &:first-child {
            margin-right: auto;
            a {
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 18L6 12L12 6' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath opacity='0.4' d='M19 18L13 12L19 6' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                background-position: center center;
                background-repeat: no-repeat;
                span {
                  opacity: 0
                }
            }
        }
        &:last-child {
            margin-left: auto;
            a {
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 18L18 12L12 6' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath opacity='0.4' d='M5 18L11 12L5 6' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                background-position: center center;
                background-repeat: no-repeat;
                span {
                  opacity: 0
                }
            }
        }
        .page-link {
            border: 0 none;
            color: $pagination-color
        }
        &.active {
            -webkit-box-shadow: $box-shadow-pagination;
            box-shadow: $box-shadow-pagination;
            .page-link {
                background-color: $black;
                color: $white;
                font-weight: $font-weight-bold
            }
        }
    }
}