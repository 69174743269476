// Sidebar
//

.main-container {
  grid-area: main;
  overflow: auto;
  padding: 0 1.25rem 1.25rem;
  @media (min-width: 992px) {
    padding: 1.25rem;
  }

  &::-webkit-scrollbar {
    width: 0.4375rem;
    height: 0.4375rem;
  }

  &::-webkit-scrollbar-track {
    background-color: $gray-100;
    @include border-radius(0.4375rem);
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparentize($primary, 0.85);
    border-left: 0;
    border-right: 0;
    @include border-radius(0.625rem);
  }
}

.main-wrapper {
  height: 100%;
  grid-area: main;
  display: block;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: "sidebar header" "sidebar main";
  position: relative;
  width: 100%;

  @include media-breakpoint-up(lg) {
    display: grid;
  }
}

.sidebar-menu {
  align-items: center;
  background: $white;
  -webkit-box-shadow: $box-shadow-sidebar !important;
  box-shadow: $box-shadow-sidebar !important; // !important property come from Third Party
  display: none;
  flex-direction: column;
  flex-wrap: wrap;
  grid-area: sidebar;
  justify-content: space-between;
  overflow-y: hidden;
  position: relative;
  z-index: 9;

  @include media-breakpoint-up(lg) {
    display: flex;
  }

  @include media-breakpoint-down(lg) {
    bottom: 0;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    width: 20rem;

    .sidebar-menu-collapse.show {
      width: 100%;
      height: 100%;
    }
  }

  &-toggle-icon {
    &.icon-xs {
      background-image: escape-svg($navbar-light-toggler-icon-bg);
    }
  }

  &-collapse {
    &.show {
      display: flex;
      flex-direction: column;
      width: 275px;
      @extend .overflow-auto;

      @include media-breakpoint-up(lg) {
        height: 100vh;
      }

      .sidebar-menu-nav-link {
        &::after {
          display: block;
        }
      }
    }

    &:not(.show) {
      width: 4rem;

      .sidebar-menu-footer .h6 {
        opacity: 0;
        overflow: hidden;
        visibility: hidden;
        width: 0;
      }
    }
  }

  &-header {
    min-height: 75px;
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    .sidebar-menu-toggle {
      margin-left: auto;
      @include transition(0.5s);

      @include media-breakpoint-down(lg) {
        display: none;
      }

      &:not(.collapsed) {
        @include transform-rotate(180);
      }
    }
  }

  &-body {
    padding: 0;
    width: 100%;
    @extend .overflow-auto;

    @include media-breakpoint-up(lg) {
      height: 100vh;
    }
  }

  &-footer {
    margin-top: auto;
    width: 100%;
  }

  &-nav {
    &-icon {
      font-size: $h4-font-size;
      vertical-align: middle;
      height: 18px;

      svg {
        vertical-align: top;
      }
    }

    a {
      padding: 10px 20px;

      &:hover,
      &:focus,
      &.active,
      &.current {
        background-color: $primary;
        color: $white !important; // Because of Third-party CSS
        font-weight: $font-weight-bold;
        svg path {
          fill: #fff;
        }
      }
    }

    &-title {
      font-size: $input-font-size;
    }

    &-link {
      padding: $sidebar-menu-nav-link-padding-y $sidebar-menu-nav-link-padding-x;
      position: relative;

      &::after {
        content: "";
        display: none;
        height: 0.1875rem;
        position: absolute;
        right: 0;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 1.875rem;
        @include border-radius(0.1875rem);
      }
    }
  }

  &-show {
    -webkit-transform: none;
    transform: none;

    @include media-breakpoint-down(lg) {
      overflow: auto;
      direction: ltr;
      scrollbar-width: thin;
      scrollbar-color: $gray-100;

      &::-webkit-scrollbar {
        width: 0.4375rem;
        height: 0.4375rem;
      }

      &::-webkit-scrollbar-track {
        background-color: $gray-100;
        @include border-radius(0.4375rem);
      }

      &::-webkit-scrollbar-thumb {
        background-color: transparentize($primary, 0.85);
        border-left: 0;
        border-right: 0;
        @include border-radius(0.625rem);
      }

      &::after {
        background-color: transparentize($modal-backdrop-bg, 0.3);
        bottom: 0;
        content: "";
        left: 0;
        overflow: hidden;
        position: fixed;
        right: 0;
        top: 0;
        animation: animation-drawer-fade-in 0.3s ease-in-out 1;
      }

      .sidebar-menu-collapse {
        background-color: $white;
        position: relative;
        z-index: 1;
      }
    }

    .sidebar-menu-nav-title {
      display: inline-block;
    }
  }

  &-brand {
    h6 {
      margin-bottom: 0px;
      font-size: 14px;
    }
  }
}

@keyframes animation-drawer-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
