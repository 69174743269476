﻿//
// Base styles
//

.btn {
  &-primary {
    // Add color because of theme mixins
    color: $white;

    &:hover,
    &:focus,
    &:active,
    &:disabled {
      color: $white; // Because of Color Variation
    }
  }

  &-50 {
    height: 3.125rem;
    min-height: 3.125rem;
    min-width: 3.125rem;
    width: 3.125rem;
  }

  &-save {
    color: $white;
    background-color: $primary;

    &:focus,
    &:hover,
    &:active {
      color: $white;
      background-color: $primary;
    }
  }

  &-outline {
    &-secondary {
      color: $primary;
      border-color: $primary;
      --bs-btn-hover-color: $primary;
      --bs-btn-hover-bg: $primary;
      --bs-btn-hover-border-color: $primary;
      --bs-btn-active-color: $primary;
      --bs-btn-active-bg: $primary;
      --bs-btn-active-border-color: $primary;

      &:focus,
      &:hover,
      &:active {
        color: $primary;
        border-color: $primary;
        background-color: $white;
      }
    }
  }
}

button.export-btn {
  min-width: 40px;
  background: linear-gradient(0deg, #dedede, #dedede),
    linear-gradient(0deg, rgba(230, 230, 230, 0.5), rgba(230, 230, 230, 0.5));
  border: 1px solid rgba(222, 222, 222, 1);
  border-radius: 4px;
  padding: 8px;
}

a.export-btn-hide {
  display: none;
}
@media screen and (max-width: 415px) {
  .MuiDataGrid-toolbarContainer button {
    margin-right: 5px !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.3rem;
    padding-left: 0.3rem;
  }
  .MuiDataGrid-toolbarContainer button:nth-child(2) {
    margin-right: 5px !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.3rem;
    padding-left: 0.3rem;
    height: 2.1rem;
    width: 1.8rem;
  }
  .d-inline-flex.btn-save {
    margin-left: 0px !important;
    padding-top: 0.125rem !important;
    padding-bottom: 0.125rem !important;
    padding-right: 0.1rem !important;
    padding-left: 0.1rem !important;
  }
}
