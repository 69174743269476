//
// Custom DataGrid Table
//

// scss-docs-start Custom Data-table
.MuiDataGrid {
  // Because of Dynamic CSS
  &-root {
    border: 0 none !important;
  }

  &-cellContent {
    width: 100%;
    text-align: left;
  }

  &-toolbarContainer {
    display: flex;
    margin-left: auto;
    padding: 0 !important;

    @include media-breakpoint-up(md) {
      flex-flow: row-reverse;
    }

    button {
      background-color: $gray-100;
      color: $gray-800;
      font-size: 0.9375rem;
      font-weight: $font-weight-normal;
      margin: 0 1rem 0 0;
      padding: 0.625rem;
      text-transform: none;
      @extend .h6;

      @include media-breakpoint-up(md) {
        margin: 0 0.25rem;
      }

      .MuiTouchRipple-root {
        display: none;
      }

      &:hover {
        background-color: $gray-400;
      }

      &:first-child {
        .MuiButton-startIcon {
          display: none;
        }

        &::before {
          content: "Display";
          margin: 0 0.25rem 0 0;
        }
      }

      &:nth-child(2) {
        // Comment if issue anywhere in Filter Button
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V8L14 2ZM18 20H6V4H13V9H18V20ZM16 11V18.1L13.9 16L11.1 18.8L8.3 16L11.1 13.2L8.9 11H16Z' fill='%233A3A3A'/%3E%3C/svg%3E%0A");
        background-position: center center;
        background-repeat: no-repeat;
        font-size: 0;
        height: 2.375rem;
        min-width: inherit;
        padding: 0;
        width: 2.375rem;

        .MuiButton-startIcon {
          display: none;
        }
      }
    }
  }

  &-main {
    font-size: 0.9rem;
    div:nth-child(2) {
      // Because of Dynamic CSS
      height: auto !important;
      width: auto !important;
    }

    .MuiDataGrid-columnSeparator {
      // Because of Dynamic CSS
      display: none !important;
    }
  }

  &-columnHeader {
    color: #54585a;
    outline: 0 none !important;
  }
  &-footerContainer {
    background: $gray-002;
    display: block;
    padding: 14px 0 0 0;
    text-align: center;

    @include media-breakpoint-up(md) {
      display: flex;
      text-align: left;
    }

    > div {
      display: block;
      order: 2;
    }

    .PaginationCounter {
      margin: 1rem 0 0;
      order: 1;

      @include media-breakpoint-up(md) {
        margin: 0;
      }
    }

    .MuiPagination-root {
      margin: 1.5rem 0 0;
      order: 3;
      ul.MuiPagination-ul {
        border: none;
      }
      @include media-breakpoint-up(md) {
        margin: 0;
      }
    }
  }

  &-cell {
    outline: 0 none !important;
    @extend .border-bottom;
    .MuiSwitch-switchBase {
      cursor: default;
    }
    .attachment-count {
      color: rgba(0, 0, 0, 0.87);
      font-size: 1rem;
    }
  }

  &-row {
    background-color: transparent !important;

    &.Mui-selected {
      background-color: transparentize($dark, 0.9) !important;
    }
    &.is-premium-1 {
      background-color: #ccc !important;
    }
  }

  &-virtualScroller {
    height: auto !important;
    width: 100% !important;
    @extend .fancy-scroll;
  }

  &-panel {
    margin-top: 0.25rem !important;

    &Header,
    &Footer {
      display: none !important;
    }

    &Content {
      max-height: 22rem !important;
      @extend .fancy-scroll;
    }
  }

  &-paper {
    border: $border-style 0.0625rem $gray-010;
    -webkit-border-radius: 0.25rem !important;
    border-radius: 0.25rem !important;
    -webkit-box-shadow: 0 0 1.25rem rgba($black, 0.2) !important;
    box-shadow: 0 0 1.25rem rgba($black, 0.2) !important;
    min-width: inherit !important;

    .MuiDataGrid-columnsPanel {
      padding: 0.5rem 1.25rem;

      &Row {
        margin: 0.5rem 0;
        padding: 0;

        .MuiFormControlLabel-root {
          margin: 0;
        }

        .MuiSwitch-sizeSmall {
          height: 1.25rem;
          margin-right: 0.625rem;
          width: 1.25rem;
          @include border-radius(0.125rem);
        }

        .MuiSwitch-switchBase {
          border: $border-style 0.0625rem $gray-300;
          padding: 0;
          transform: none !important; // Because of Third Party CSS
          // @include border-radius(0);

          &.Mui-checked {
            color: $primary;
            border-color: $primary;
          }

          .MuiSwitch-thumb {
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.3738 0.292893C15.7643 0.683417 15.7643 1.31658 15.3738 1.70711L6.20712 10.8738C5.81659 11.2643 5.18343 11.2643 4.7929 10.8738L0.626237 6.70711C0.235712 6.31658 0.235712 5.68342 0.626237 5.29289C1.01676 4.90237 1.64993 4.90237 2.04045 5.29289L5.50001 8.75245L13.9596 0.292893C14.3501 -0.0976311 14.9833 -0.0976311 15.3738 0.292893Z' fill='white'/%3E%3C/svg%3E%0A");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 0.875rem auto;
            box-shadow: none;
            height: 1.125rem;
            width: 1.125rem;
            @include border-radius(0);
          }
        }

        .MuiSwitch-track {
          background: transparent;
          @include border-radius(0.125rem);
        }

        .MuiFormControlLabel-label {
          color: $gray-800;
          font-size: 0.875rem;
        }
      }
    }
  }

  &-menu {
    margin: 0.25rem 0 0 !important; // Because of Dynamic Margin

    .MuiPaper-elevation {
      border: $border-style 0.0625rem $gray-300;
      -webkit-box-shadow: 0 0 1.25rem rgba($black, 0.2);
      box-shadow: 0 0 1.25rem rgba($black, 0.2);
      min-width: 6.25rem;
      @include border-radius(0.25rem);
    }

    ul {
      padding: 0.625rem;

      li {
        font-size: 0.875rem;
        @include border-radius(0.25rem);

        &:hover {
          background-color: transparentize($primary, 0.9);
          color: $primary;
        }
        .edit-icon {
          width: 1em;
          height: 1em;
          font-size: 1.71429rem;
          margin-left: 2px;
        }
      }
    }
  }

  &-actionsCell {
    button {
      background: $gray-002;
      color: $black;
      height: 1.625rem;
      padding: 0;
      width: 1.625rem;
      @include border-radius(0.1875rem);
      @include transform-rotate(90);

      svg {
        height: auto;
        width: 1.5rem;
      }
    }
  }
}

.completed {
  color: #2a9d8f;
  text-transform: capitalize;
}

.authorized_pending_review {
  color: #ec1ff3;
  text-transform: capitalize;
}

.pending {
  color: #0000ff;
  text-transform: capitalize;
}

.failed,
.invalid_request {
  color: #ffa500;
  text-transform: capitalize;
}

.authorized {
  color: #51e2e7;
  text-transform: capitalize;
}

.inprogress {
  color: #6f48fc;
  text-transform: capitalize;
}

.transmitted {
  color: #00ff00;
  text-transform: capitalize;
}

.cancelled {
  color: #fc37c1;
  text-transform: capitalize;
}

.declined {
  color: #f70000;
  text-transform: capitalize;
}

// scss-docs-start Custom Data-table
// scss-docs-start Data-table Search
.MuiFormControl-root {
  width: 100%;
}
.location-select,
.MuiDataGrid-root {
  .MuiFormControl-root {
    @include media-breakpoint-up(md) {
      max-width: 20rem;
    }
  }
}

.MuiInputBase-formControl {
  font-size: 0.875rem;
  &::before,
  &::after {
    display: none;
  }
}

// scss-docs-start Data-table Search
// scss-docs-start Data-table Pagination
.MuiTablePagination {
  &-toolbar {
    padding: 0;
  }

  &-counter {
    order: 1;
  }
}

.MuiPagination-ul {
  border: solid 1px $gray-300;
  display: inline-flex;
  flex-wrap: nowrap; // Remove if issue anywhere
  justify-content: space-between;
  @include border-radius(0.25rem);

  li {
    &:first-child {
      margin-right: auto;
    }

    &:last-child {
      margin-left: auto;
    }
  }

  button {
    border: 0 none;

    &:hover {
      background-color: transparentize($black, 0.9);
    }

    &.Mui-selected {
      background-color: #54585a !important;
      -webkit-box-shadow: $box-shadow-pagination;
      box-shadow: $box-shadow-pagination;
      color: $white !important;
      font-weight: $font-weight-bold;
    }
  }

  .MuiPaginationItem-page {
    margin: 0;
  }

  .MuiPaginationItem-previousNext {
    background-repeat: no-repeat;
    background-position: center center;
    margin: 0;
  }
}

// scss-docs-start Data-table Pagination

.filter-bar .MuiOutlinedInput-root.MuiInputBase-formControl {
  padding: 0;
  height: 100%;
}

.filter-bar .MuiTextField-root {
  margin-right: 5px;
  border-radius: 4px;
}

.filter-bar .MuiIconButton-root {
  background: linear-gradient(0deg, #dedede, #dedede),
    linear-gradient(0deg, #e6e6e6, #e6e6e6);
  border-radius: 0 8% 8% 0;
  margin-right: 0;
  height: 100%;
}

.filter-bar
  .MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-sizeMedium {
  max-height: inherit;
  height: 100%;
}

div.table-cardcontent {
  padding: 0px !important;
}
.apexcharts-canvas.apexcharts-theme-light text.apexcharts-title-text {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  fill: #54585a !important;
}

.MuiButtonBase-root.MuiToggleButton-root.MuiToggleButton-sizeSmall {
  padding: 0px 24px;
}

.MuiFormHelperText-root.Mui-error.MuiFormHelperText-sizeMedium.MuiFormHelperText-filled {
  position: absolute;
  bottom: -20px;
  font-size: 12px;
}
.MuiDataGrid-cell {
  overflow: unset !important;
  white-space: unset !important;
}

.CustomSearchHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}