//
// Base styles
//

.accordion {
  &-header,
  &-collapse {
    z-index: 1;
    @extend .position-relative;
  }
  &-item {
    margin: 1.25rem 0 0;
    @include border-radius(0.75rem);
    @extend .position-relative;
    @extend .overflow-hidden;
    &:first-child {
      @extend .m-0;
    }
    &::after {
      border: $border-style 0.0625rem $primary;
      content: "";
      @include border-radius(0.75rem);
      @extend .bottom-0;
      @extend .end-0;
      @extend .h-100;
      @extend .position-absolute;
      @extend .start-0;
      @extend .top-0;
      @extend .w-100;
    }
  }
  &-button {
    &:not(.collapsed) {
      @extend .border-0;
      @extend .fw-bold;
      @extend .shadow-none;
      svg path {
        fill: #fff;
      }
    }
  }
  &-body {
    font-size: $small-font-size;
    @extend .pt-0;
    @include media-breakpoint-up(xl) {
      padding-right: 40%;
    }
  }
  &-flush {
    .accordion {
      &-item {
        &::after {
          @extend .d-none;
        }
      }
      &-body {
        .show & {
          .sidebar-menu-nav a {
            padding-left: 3.25rem;
          }
        }
      }
      &-button {
        background-color: $white;
        padding: 10px 20px;
        line-height: 28px;
        &::after {
          @extend .d-none;
          .show & {
            @extend .d-block;
          }
        }
        &[aria-expanded="true"] {
          @extend .bg-primary;
          @extend .text-white;
        }
        &:not(.collapsed) {
          &::after {
            background-image: $accordion-button-icon-white;
          }
        }
      }
    }
  }
}
