// stylelint-disable property-disallowed-list

@mixin transform-rotate($degrees) {
  -webkit-transform:           rotate(#{$degrees}deg);
  -moz-transform:              rotate(#{$degrees}deg);
  -ms-transform:               rotate(#{$degrees}deg);
  transform:                   rotate(#{$degrees}deg)
}

@mixin transform-translate($translateX,$translateY) {
  -webkit-transform:           rotate($translateX,$translateY);
  -moz-transform:              rotate($translateX,$translateY);
  -ms-transform:               rotate($translateX,$translateY);
  transform:                   rotate($translateX,$translateY)
}

@mixin transition($transition-none) {
  -webkit-transition:          $transition-none;
  -moz-transition:             $transition-none;
  -ms-transition:              $transition-none;  
  transition:                  $transition-none
}

@mixin transition($transition-second) {
  -webkit-transition:          all $transition-second;
  -moz-transition:             all $transition-second;
  -ms-transition:              all $transition-second;
  transition:                  all $transition-second
}

@mixin animation-delay($animation-delay) {
  -webkit-animation-delay:     $animation-delay;
  -moz-animation-delay:        $animation-delay;
  -ms-animation-delay:         $animation-delay;  
  animation-delay:             $animation-delay
}
// scss-docs-end btn-variant-mixin
