﻿// Utilities
//
//
$utilities: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$utilities: map-merge(
  (
    "line-height": (
      property: line-height,
      class: lh,
      values: (
        1: 1,
        sm: $line-height-sm,
        md: $line-height-md,
        base: $line-height-base,
        lg: $line-height-lg,
      )
    )
    // scss-docs-end utils-visibility
  ),
  $utilities
);

// Base styles
//
html, body, #__next {
    scroll-behavior: smooth;
    height: 100%;
    overflow: inherit
}
::selection {
    color: $white;
    background: $primary;
}
body {
    scrollbar-width: thin;
    scrollbar-color: $gray-100;
    &::-webkit-scrollbar {
      width: .4375rem;
      height: .4375rem
    }
    &::-webkit-scrollbar-track {
        background-color: $gray-100;
        @include border-radius(.4375rem)
    }
    &::-webkit-scrollbar-thumb {
        background-color: transparentize($primary, .85);
        border-left: 0;
        border-right: 0;
        @include border-radius(.625rem)
    }
}

// scss-docs-start cms-pages-design
.cms-pages {
    background-color: $white;
    padding: 5.5rem 0 0;
    @include media-breakpoint-up(sm) {
        padding: 6.875rem 0 0
    }
    @include media-breakpoint-up(lg) {
        padding: 9.875rem 0 0
    }
}
// scss-docs-end cms-pages-design
// scss-docs-start error-page-space
.error-page {
    padding: 12% 0
}
// scss-docs-end error-page-space
// scss-docs-start bg-property
.bg {
    &-cover {
        background-size: cover
    }
    &-contain {
        background-size: contain
    }
    &-lightest {
        background-color: $gray-002
    }
    &-features {
        background-image: $vector-features;
        @include background-properties-start-top
    }
    &-opacity {
        &-5 {
        --bs-bg-opacity: .05
        }
        &-10 {
        --bs-bg-opacity: .1
        }
        &-7 {
        --bs-bg-opacity: .7
        }
    }
}
// scss-docs-end bg-property
// scss-docs-start objectfit-style
.objectfit {
    &-cover {
        object-fit: cover
    }
    &-contain {
        object-fit: contain
    }
}
// scss-docs-end objectfit-style
// scss-docs-start opacity maps
@each $opacity, $opacity-value in $opacity-type {
    .opacity-#{$opacity} {
      opacity: $opacity-value;
    }
}
// scss-docs-end opacity maps
// scss-docs-start blink-icon
.animation-blink {
    animation: animationBlink 1s steps(5, start) infinite
}
@keyframes animationBlink {
    to {
      visibility: hidden;
    }
}
// scss-docs-end blink-icon
// scss-docs-end rating-stars
.rating-stars {
    align-items: center;
    display: flex;
    margin: 0;
    li {
        background-image: $vector-star;
        background-position: center;
        background-repeat: no-repeat;
        font-size: 0;
        height: 1.125rem;
        width: 1rem;
        &.active {
            background-image: $vector-star-on
        }
    }
}
// scss-docs-end rating-stars
// scss-docs-start utils-interaction
.cursor {
    &-default {
        cursor: $form-range-cursor-default
    }
    &-not-allow, &-disable {
        cursor: $form-cursor-not-allowed
    }
    &-pointer {
        cursor: $form-range-track-cursor
    }
}
// scss-docs-end utils-interaction

// scss-docs-start page-title
.page-heading {
    text-align: center;
    h2 {
        align-items: center;
        color: $black;
        display: inline-flex;
        font-weight: $font-weight-bold;
        margin: 0 0 2rem;
        position: relative;
        vertical-align: top;
        @include media-breakpoint-up(lg) {
            margin: 0 0 3.125rem;
        }
        &::before, &::after {
            background: $primary;
            content: '';
            height: .25rem;
            margin: 0 .75rem;
            width: 2.5rem;
            @include border-radius(.625rem);
            @include media-breakpoint-up(lg) {
                height: .375rem;
                margin: 0 1.875rem;
                width: 5rem
            }
        }
    }
}
// scss-docs-end page-title
// scss-docs-end font-weight-medium
.fw-medium {
    font-weight: $font-weight-medium !important
}
// scss-docs-end font-weight-medium
// scss-docs-end border-radius
.rounded-wide {
    @include border-radius(.75rem)
}
// scss-docs-end border-radius
// scss-docs-end border-style
.border.border-dashed {
    border-style: dashed !important
}
// scss-docs-end border-style
// scss-docs-start section-overlay
.section-overlay {
    position: relative;
    .container {
        position: relative;
        z-index: 1
    }
    &::after {
        background: $white;
        bottom: 0;
        content: '';
        height: 40%;
        left: 0;
        position: absolute;
        right: 0;
        width: 100%
    }
}
// scss-docs-end section-overlay
// scss-docs-end box-shadow-xl
.shadow {
    &-xl {
        -webkit-box-shadow: .125rem .125rem 1.875rem transparentize($gray-900, .9105);
        box-shadow: .125rem .125rem 1.875rem transparentize($gray-900, .9105)
    }
    &-shape {
        &::after {
            background: $primary;
            bottom: 0;
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            -webkit-transform: translate(.3125rem, .3125rem);
            transform: translate(.3125rem, .3125rem);
            width: 100%;
            @include border-radius(.4375rem);
            @include media-breakpoint-up(lg) {
                -webkit-transform: translate(.625rem, .625rem);
                transform: translate(.625rem, .625rem)
            }
        }
        &-overlap {
            z-index: 1
        }
    }
}
// scss-docs-end box-shadow-xl
// scss-docs-end default-section
section {
    padding: 1.5rem 0;
    @include media-breakpoint-up(sm) {
        padding: 3rem 0
    }
    @include media-breakpoint-up(lg) {
        padding: 5rem 0
    }
}
// scss-docs-end default-section
// scss-docs-start fancy-scroll
.fancy-scroll {
    overflow: auto;
    direction: ltr;
    scrollbar-width: thin;
    scrollbar-color: $gray-100;
    &::-webkit-scrollbar {
      width: .4375rem;
      height: .4375rem;
    }
    &::-webkit-scrollbar-track {
        background-color: $gray-100;
        @include border-radius(.4375rem)
    }
    &::-webkit-scrollbar-thumb {
        background-color: transparentize($primary, .85);
        border-left: 0;
        border-right: 0;
        @include border-radius(.625rem)
    }
  }
  .overflow-auto, .table-responsive {
    @extend .fancy-scroll;
  }
// scss-docs-end fancy-scroll