// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s, `<ul>`s or `<ol>`s.

.nav-link {
  color: $nav-link-color;
  line-height: 1.25rem;
  &:hover, &:focus, &.active {
    color: $nav-link-hover-color !important // Because of dynamic Nested Classes
  }
}


//
// Tabs
//

.nav-tabs {
  // scss-docs-start nav-tabs-css-vars
  &-custom {
    border: 0 none;
    padding: 0 0 1.3125rem;
    position: relative;
    &::after {
      background: $gray-002;
      bottom: 0;
      content: '';
      display: block;
      height: 1.25rem;
      position: absolute;
      left: -1.25rem;
      right: 0;
      width: calc(100% + 2.5rem)
    }
  }
  @include media-breakpoint-down(md) {
      display: block
  }
  .nav-item {
    @include media-breakpoint-up(md) {
      margin: 0 1.25rem;
      &:first-child {
        margin-left: 0
      }
    }
  }

  .nav-link {
    border: 0 none;
    border-bottom: $border-style .125rem transparent;
    color: $gray-800;
    min-width: 8.75rem;
    padding: .75rem;
    text-align: left;
    @include media-breakpoint-up(md) {
      padding: 1rem .75rem;
      text-align: center
    }
    &:hover {
      color: $primary
    }
    &.active {
      font-weight: $font-weight-bold
    }
  }
  // scss-docs-end nav-tabs-css-vars
}


//
// Pills
//

.nav-pills {
  .nav-link {
    @include font-size($h6-font-size)
  }
  .nav-link.active,
  .show > .nav-link {
    font-weight: $font-weight-bold
  }
  &-custom {
    .nav-item {
      margin: 0 .75rem .75rem 0;
      @include media-breakpoint-up(md) {
        margin: 0 1rem 1rem 0;
      }
      .nav-link {
        background-color: $gray-100;
        border: $border-style .0625rem $gray-100;
        height: 2.75rem;
        justify-content: center;
        padding: 0;
        width: 3.75rem;
        @include border-radius(.25rem);
        @include media-breakpoint-up(md) {
          height: 5.25rem;
          width: 6.25rem
        }
        span {
          @extend .h-100;
          @extend .w-100;
        }
        &:hover {
          border-color: $primary
        }
        &.active {
          background-color: transparentize($primary, .9);
          border-color: $primary
        }
      }
    }
  }
}