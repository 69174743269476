// scss-docs-start import-stack
// Configuration
@import "variables";
@import "mixins";
@import "utilities";

// B. Include Default Bootstrap or any optional Bootstrap CSS as needed
@import "~bootstrap/scss/bootstrap";
@import "~react-bootstrap-sidebar-menu/dist/sidebar-menu.scss";

@import "tables";
@import "fonts";
@import "buttons";
@import "dropdown";
@import "nav";
@import "navbar";
@import "accordion";
@import "sidebar";
@import "pagination";

// D. Include any default variable overrides here (though functions won't be available)
#root {
  min-height: 100%;
  display: contents;
}

.filenotfound {
  align-self: center;
}

.MuiInputLabel-root {
  font-size: 0.9rem !important;
  line-height: 0.8em !important;
  top: -3px;
}

.MuiInputBase-input.MuiInput-input {
  font-size: 0.9rem;
  margin: 5px;
}

.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input,
.MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input,
.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 5px 5px;
  font-size: 0.9rem;
}

.MuiDataGrid-columnHeaderRow {
  background-color: $white;
}

.MuiDataGrid-footerContainer {
  background-color: $white;
}

.MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0.3125rem 0.5rem;
}

.location-icon {
  display: none;
  padding: 4px;
  margin: 0;
  min-width: auto;
  border: 1px solid #dedede;
  border-radius: 3px;
}
.location-select {
  margin-left: auto;
}
.user-details {
  text-align: right;
}
.user-details-mobile {
  display: none;
}
@media screen and (max-width: 676px) {
  .user-details {
    display: none;
  }
  .user-details-mobile {
    display: block;
  }
  .location-icon {
    display: block;
  }
  .location-select {
    visibility: hidden;
    width: 0px;
    margin-left: unset;
  }
  .location-select .MuiInputBase-root.MuiOutlinedInput-root {
    width: 1px;
  }
  .main-container > .MuiBox-root {
    padding: 5px;
  }
  .MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-outlined {
    margin-left: 0;
  }
  .mobile-header h6 {
    font-size: 0.9rem;
  }
}
a:hover {
  font-weight: 700;
}

.export-csv {
  min-width: 116px;
}

@media (max-width: 991px) {
  .hide-crumb {
    display: none !important;
  }
  .filter-bar .date-filter {
    width: 50%;
    display: table-cell;
  }
  .filter-bar-date {
    display: inline-flex;
  }
}

@media (min-width: 992px) {
  .show-crumb {
    display: none !important;
  }
}

@media (max-width: 480px) {
  .export-csv {
    min-width: 116px;
  }
  .filter-bar-date {
    display: block;
    margin-top: 1rem;
  }
}

.custom-loader {
  position: absolute;
  top: 0;
}

hr.MuiDivider-fullWidth {
  opacity: 1;
}

span.MuiStepLabel-alternativeLabel {
  text-align: left;
  .Mui-active {
    color: #2a9d8f;
  }
}
.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
  width: 100%;
}
.transaction-step {
  background-color: rgba(204, 204, 204, 0.2);
  padding: 20px;
}

div.step-description {
  margin-left: 0;
  padding-left: 0;
  border-left: unset;
}

.MuiCardHeader-content {
  .MuiCardHeader-title {
    font-size: 1.1rem;
    font-weight: 700;
  }
}
div.MuiPaper-elevation1 {
  padding: 8px 0 8px 0;
}
.MuiPaper-elevation1 div.MuiTabPanel-root {
  padding: 0;
  margin-top: -30px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation1.charts-header {
  text-align: right;
  h6 {
    text-align: center;
  }
}

.charts-header .MuiTabs-root {
  min-height: 36px;
}

.MuiTabs-flexContainer .MuiTab-root.MuiTab-textColorPrimary {
  text-transform: capitalize;
  min-height: 36px;
  padding: 5px;
}
.donut-chart
  div.apexcharts-legend.apx-legend-position-bottom
  .apexcharts-legend-series {
  align-items: baseline;
  margin: 5px 10px !important;
}

.transaction-details .MuiGrid-root.MuiGrid-container {
  align-items: center;
}

.transaction-details .MuiGrid-root.MuiGrid-item h5,
.transaction-details .MuiGrid-root.MuiGrid-item span,
.transaction-details
  .MuiGrid-container
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-8,
.transaction-details
  .MuiGrid-container
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-8 {
  font-family: "Roboto";
  font-style: normal;
  color: #54585a;
}

.transaction-details .MuiGrid-root.MuiGrid-item h5 {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}

.transaction-details .MuiGrid-root.MuiGrid-item span {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.transaction-details
  .MuiGrid-container
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-8,
.transaction-details
  .MuiGrid-container
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-8 {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
p#demo-simple-select-helper-text {
  margin-left: 0;
}
label#demo-simple-select-label {
  line-height: initial !important;
}
label#secret-key-input-label {
  top: 3px !important;
}
input#secret-key-input {
  height: 25px;
}