﻿@font-face {
  font-family: 'icons';
  src: url('fonts/icons.eot?y1lx60');
  src: url('fonts/icons.eot?y1lx60#iefix') format('embedded-opentype'),
    url('fonts/icons.ttf?y1lx60') format('truetype'),
    url('fonts/icons.woff?y1lx60') format('woff'),
    url('fonts/icons.svg?y1lx60#icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: $font-family-sans-icons;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-circle-minus-white:before {
  content: "\e92a";
}

.icon-dashboard:before {
  content: "\e90b";
}

.icon-recordes:before {
  content: "\e90c";
}

.icon-products:before {
  content: "\e933";
}

.icon-close-filled:before {
  content: "\e91a";
  color: $primary;
}

.icon-circle-plus-white:before {
  content: "\e91d";
}

.icon-user:before {
  content: "\e902";
}

.icon-container svg path {
  fill: $primary;
}