.credit-cards-list {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
/*   
.credit-cards-list h3 {
  margin-bottom: 15px;
  color: #444;
} */

.cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Add gap between cards */
}

.credit-card {
  position: relative;
  width: calc(50% - 10px); /* Two cards per row */
  height: auto;
  padding: 15px;
  border-radius: 15px;
  background: linear-gradient(135deg, #2e691c, #c5c5c6);
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

.credit-card-deleted {
  position: relative;
  width: calc(50% - 10px); /* Two cards per row */
  height: auto;
  padding: 20px;
  border-radius: 15px;
  background: linear-gradient(135deg, #c53e4c, #c5c5c6);
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

.credit-card-onetimeuse {
  position: relative;
  width: calc(50% - 10px); /* Two cards per row */
  height: auto;
  padding: 20px;
  border-radius: 15px;
  background: linear-gradient(135deg, #1f68a5, #c5c5c6);
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

 /* background: linear-gradient(135deg, #54585a, #c5c5c6); */
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 15px; */
}

.credit-card-chip {
  width: 40px;
  height: 30px;
  background-color: gold;
  border-radius: 4px;
}

.card-type {
  font-size: 18px;
  font-weight: bold;
}

.credit-card-number {
  font-size: 20px;
  letter-spacing: 2px;
  margin-bottom: 10px;
  text-align: center;
}

.credit-card-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin-bottom: 10px; */
}

.credit-card-details p {
  margin: 0;
}

.card-actions {
  display: flex;
  justify-content: flex-end;
}

.card-icon {
  margin-left: 10px;
  cursor: pointer;
  color: white;
  font-size: 18px;
}

.card-icon:hover {
  color: #ddd;
}


.custom-button-link {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #55575a; /* Primary color */
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.custom-button-link:hover {
  background-color: #333335; /* Darker shade for hover effect */
}

.word-break {
  word-break: break-all;
}