// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown {
  &-toggle {
    &::after {
      content: none;
    }
  }

  &-header {
    p {
      color: rgba(84, 88, 90, 0.6)
    }
  }

  &-item {
    svg {
      margin-right: 10px;
      vertical-align: text-bottom;
    }

    &.active,
    &:active {
      font-weight: $font-weight-bold
    }
  }

  &-toggle-arrow {
    &::after {
      background-image: $dropdown-arrow;
      background-position: center center;
      background-repeat: no-repeat;
      border: 0 none;
      height: .5rem;
      vertical-align: middle;
      width: .875rem;
      @include transition(.3s)
    }

    &[aria-expanded='true']::after {
      @include transform-rotate(180)
    }
  }
}